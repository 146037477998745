import React from "react";
import {
  Heading,
  Box,
  Center,
  Text,
  AspectRatio,
  Container,
  Flex,
  VStack,
  Image,
} from "@chakra-ui/react";
import { HeadFC, Page } from "gatsby";
import HeroWithCoverImage from "../components/templatesKart/HeroWithImageCover";
import Navbar from "../components/templatesKart/NavbarCenteredLinks";
import HeroWithGradientCover from "../components/templatesKart/HeroWithGradientCover";
import { graphql, PageProps } from "gatsby";
import { Map, Marker } from "pigeon-maps";
import Layout from "../components/Layout";
import { Link as GatsbyLink } from "gatsby";

import Nidal from "../images/nidal.jpg";
import Samira from "../images/samira.jpeg";

// type DataProps = {
//   allFile: {
//     edges: {
//       node: {
//         relativeDirectory: string;
//         id: string;
//       }[];
//     };
//   };
// };

const IndexPage = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  const murals = data.allMdx?.nodes;
  return (
    <Layout>
      {/* <Container> */}
      <Box py={8}>
        <Center>
          <AspectRatio maxW="70%" flex="1 1 auto" ratio={16 / 9}>
            <iframe
              src="https://www.youtube-nocookie.com/embed/P3S4MZBW2YA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </AspectRatio>
        </Center>
        {/* </Container> */}
        {/* <Container p={6}> */}
        <VStack p={6}>
          <Center my={4}>
            <Heading>In Loving Memory</Heading>
          </Center>
          <Flex justifyContent="space-around" w="100vw">
            <VStack>
              <Heading size="lg" textAlign="center" pb={2}>
                Nidal Sammur
              </Heading>
              <AspectRatio w="30vw" ratio={1}>
                <Image src={Nidal} />
              </AspectRatio>
            </VStack>
            <VStack>
              <Heading size="lg" textAlign="center" pb={2}>
                Samira Agha
              </Heading>
              <AspectRatio w="30vw" ratio={1}>
                <Image src={Samira} />
              </AspectRatio>
            </VStack>
          </Flex>
        </VStack>
        {/* </Container> */}

        {/* <HeroWithCoverImage /> */}

        {/* <ul>
        {murals.map((mural) => (
          <li key={mural.id}>
            <GatsbyLink to={`/murals/${mural.frontmatter?.slug}`}>
              {mural.frontmatter?.title} - {mural.frontmatter?.muralDate}
            </GatsbyLink>
          </li>
        ))}
      </ul> */}

        {/* <HeroWithGradientCover /> */}
      </Box>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    allMdx {
      nodes {
        id
      }
    }
  }
`;

export const Head: HeadFC = () => <title>Home Page</title>;
